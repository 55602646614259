import { FC, Suspense, useEffect } from 'react';
import { Location } from 'history';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import { setLocale } from 'yup';

import 'nprogress/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';

import { Button, LazyLoad, PageContainer } from '~/components';
import config from '~/config';

import { Router } from './Router';

setLocale({
  mixed: {
    required: 'This field is required',
  },
  string: {
    email: 'Invalid email',
  },
});

interface AppProps {
  location: Location;
}

export const App: FC<AppProps> = ({ location }) => {
  useEffect(() => {
    const root = document.querySelector('#perfect-product-finder');

    if (root) {
      (root as any).style.opacity = 1;
    }

    const pageLoader = document.querySelector('#page-loader');

    if (pageLoader) {
      (pageLoader as any).style.opacity = 0;

      setTimeout(() => {
        (pageLoader as any).style.display = 'none';
      }, 120);
    }
  }, []);

  return (
    <div className="App">
      <Helmet {...config.seo} />

      <ErrorBoundary
        onReset={() => {
          window.location.reload();
        }}
        fallbackRender={({ resetErrorBoundary }) => (
          <PageContainer>
            <Row className="justify-content-md-center">
              <Col md="6" lg="4">
                <h1 className="mb-4">An error has occurred</h1>

                <Button color="primary" onClick={resetErrorBoundary}>
                  Refresh the page
                </Button>
              </Col>
            </Row>
          </PageContainer>
        )}
      >
        <Suspense fallback={<LazyLoad />}>
          <Router location={location} />
        </Suspense>
      </ErrorBoundary>

      <ToastContainer />
    </div>
  );
};
