import { createContext } from 'react';

import { getSdk } from '~/lib';

export const SdkContext = createContext<any | undefined>(undefined);

export const useSdk = () =>
  getSdk((url, options) =>
    fetch(url, {
      ...options,
      headers: {
        ...(options.headers || {}),
      },
    }),
  );
