import { FC } from 'react';
import { Location } from 'history';
import { Switch, Route } from 'react-router-dom';

import {
  Login,
  NotFound,
  StoresList,
  StoreDetail,
  Usage,
  TiersList,
  TierDetail,
} from '~/containers';

interface RouterProps {
  location?: Location;
}

export const Router: FC<RouterProps> = ({ location }) => (
  <Switch location={location}>
    <Route exact path="/" component={StoresList} />
    <Route exact path="/stores/:id" component={StoreDetail} />
    <Route exact path="/tiers" component={TiersList} />
    <Route exact path="/tiers/:id" component={TierDetail} />
    <Route exact path="/usage" component={Usage} />
    <Route exact path="/login" component={Login} />
    <Route component={NotFound} />
  </Switch>
);
