import { FC, useState } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import { useAccount } from '~/hooks';

export const Navigation: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isBusy, setBusy] = useState(false);
  const { logout } = useAccount();

  const handleLogout = async () => {
    if (isBusy) return;

    setBusy(true);
    const success = await logout();

    if (success) {
      toast('Logged out.', { type: 'success' });
    } else {
      setBusy(false);
    }
  };

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar color="light" light expand="md">
        <NavbarBrand
          className="mr-3 d-flex flex-direction-row align-items-center"
          tag={RRNavLink}
          to="/"
        >
          <img src="/images/ppf-logo.png" height="24px" alt="Logo" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-3" navbar>
            <NavItem>
              <NavLink tag={RRNavLink} exact to="/">
                Stores
              </NavLink>
            </NavItem>
          </Nav>
          <Nav className="mr-3" navbar>
            <NavItem>
              <NavLink tag={RRNavLink} to="/tiers">
                Tiers
              </NavLink>
            </NavItem>
          </Nav>
          <Nav className="mr-3" navbar>
            <NavItem>
              <NavLink tag={RRNavLink} to="/usage">
                Usage
              </NavLink>
            </NavItem>
          </Nav>
          <Nav className="ml-auto mr-3" navbar>
            <NavItem>
              <NavLink tag={RRNavLink} to="/settings">
                Settings
              </NavLink>
            </NavItem>
          </Nav>
          <Nav navbar>
            <NavItem>
              <NavLink href="#" onClick={handleLogout}>
                Logout
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};
