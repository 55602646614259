import { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useFormState } from 'react-hook-form';
import {
  Button as RSButton,
  ButtonProps as RSButtonProps,
  Spinner,
} from 'reactstrap';

import config from '~/config';

import './styles.css';

interface ButtonProps extends RSButtonProps {
  loading?: boolean;
  type?: 'button' | 'submit';
}

export const Button: FC<ButtonProps> = ({
  children,
  loading,
  type = 'button',
  ...props
}) => {
  return (
    <RSButton {...props} className={`${props.className} button`} type={type}>
      {children}
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: loading ? 1 : 0 }}
          exit={{ opacity: 0 }}
          transition={config.transition}
        >
          <div className={`spinner__wrapper bg-${props.color || 'secondary'}`}>
            <Spinner size="sm" />
          </div>
        </motion.div>
      </AnimatePresence>
    </RSButton>
  );
};

export const FormButton: FC<ButtonProps> = (props) => {
  const { isSubmitting, isValid } = useFormState();

  return (
    <Button
      {...props}
      color={props.color || 'primary'}
      type={props.type || 'submit'}
      disabled={!isValid || isSubmitting}
      loading={isSubmitting}
    />
  );
};
