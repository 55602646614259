import {
  Pagination as RSPagination,
  PaginationItem,
  PaginationLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { PageInfo } from '~/graphql/sdk';

const PAGE_SIZES = [10, 20, 25, 50];

interface PaginationProps {
  changeParams(update: Record<string, any>): void;
  pageInfo: PageInfo | undefined;
  totalCount?: number;
  pageSize: number | undefined;
}

export const Pagination = ({
  changeParams,
  pageInfo,
  totalCount,
  pageSize = 10,
}: PaginationProps) => {
  const setNext = () => {
    changeParams({ prev: undefined, next: pageInfo?.endCursor });
  };

  const setPrev = () => {
    changeParams({ next: undefined, prev: pageInfo?.startCursor });
  };

  return (
    <div className="w-100 d-flex flex-row justify-content-between align-items-center">
      <div className="d-flex">
        <RSPagination aria-label="Page navigation" className="mb-0 mr-4">
          <PaginationItem disabled={!Boolean(pageInfo?.hasPreviousPage)}>
            <PaginationLink previous href="#" onClick={setPrev}>
              Previous
            </PaginationLink>
          </PaginationItem>
          <PaginationItem disabled={!Boolean(pageInfo?.hasNextPage)}>
            <PaginationLink next href="#" onClick={setNext}>
              Next
            </PaginationLink>
          </PaginationItem>
        </RSPagination>
        <UncontrolledDropdown>
          <DropdownToggle color="primary" caret>
            {pageSize ?? 10}
          </DropdownToggle>
          <DropdownMenu>
            {PAGE_SIZES.map((size) => (
              <DropdownItem onClick={() => changeParams({ pageSize: size })}>
                {size}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      {Boolean(totalCount) && <p>total: {totalCount}</p>}
    </div>
  );
};
