import { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { getError } from '~/lib';

import { UserContext } from '~/providers';

import { useSdk } from './useSdk';

export const useAccount = () => {
  const queryClient = useQueryClient();
  const sdk = useSdk();

  const { user: userData, dispatch, isLoggedIn, hasLoaded } = useContext(
    UserContext,
  );
  const [called, setCalled] = useState(false);

  const { data, error, isLoading } = useQuery(
    ['adminSession'],
    () => sdk.adminSession().then((res) => res.adminSession),
    {
      onSettled: () => {
        if (!called) {
          setCalled(true);
        }
      },
      retry: false,
    },
  );

  useEffect(() => {
    if (!data && (error || (called && !isLoading))) {
      dispatch({ type: 'error' });
    } else if (data && !error) {
      dispatch({ type: 'success', user: data });
    }
  }, [error, isLoading, called, data]);

  const login = async (token: string) => {
    try {
      const res = (
        await sdk.authenticateAdmin({
          token,
        })
      ).authenticateAdmin;

      queryClient.setQueryData(['adminSession'], res);
      return true;
    } catch (err) {
      toast(getError(err), { type: 'error' });
      return false;
    }
  };

  const logout = async () => {
    try {
      await sdk.logoutAdmin();

      queryClient.setQueryData(['adminSession'], undefined);
      dispatch({ type: 'error' });
      return true;
    } catch (err) {
      toast(getError(err), { type: 'error' });
      return false;
    }
  };

  return {
    dispatch,
    user: userData,
    error,
    isLoggedIn,
    hasLoaded,
    login,
    logout,
  };
};
