import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as Yup from 'yup';

import 'flatpickr/dist/themes/light.css';

import { App } from '~/containers';
import { UserProvider } from '~/providers';

import '~/styles/main.scss';

Yup.setLocale({
  mixed: {
    required: 'This field is required',
  },
  string: {
    email: 'Invalid email',
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <UserProvider>
      <BrowserRouter>
        <Switch>
          <Route component={App} />
        </Switch>
      </BrowserRouter>
    </UserProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('perfect-product-finder'),
);
