import { useMemo, useState } from 'react';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';

import {
  TiersQuery,
  TierFilter,
  TierQuery,
  CursorPaging,
  TiersQueryVariables,
  SortDirection,
} from '~/graphql/sdk';

import { useSdk } from './useSdk';

export const useTiers = (params: Record<string, any>) => {
  const sdk = useSdk();
  const queryClient = useQueryClient();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const toggleCreateModal = () => setShowCreateModal(!showCreateModal);

  const [debouncedSearch] = useDebounce(params.search, 250);

  const filters = useMemo(
    () =>
      ({
        ...(Boolean(debouncedSearch) && {
          or: [{ name: { iLike: `%${debouncedSearch}%` } }],
        }),
        ...(params.showPublicOnly && {
          and: [{ isPublic: { is: true } }],
        }),
      } as TierFilter),
    [debouncedSearch, params.showPublicOnly],
  );

  const pagination = useMemo(
    () =>
      ({
        ...(params?.prev
          ? { last: params.pageSize ?? 10 }
          : { first: params.pageSize ?? 10 }),
        ...(params?.prev && { before: params.prev }),
        ...(params?.next && { after: params.next }),
      } as CursorPaging),
    [params?.next, params?.prev, params?.pageSize],
  );

  const sorting = useMemo(
    () =>
      [
        ...(Boolean(debouncedSearch)
          ? [
              { field: 'name', direction: SortDirection.Asc },
              { field: 'createdAt', direction: SortDirection.Desc },
            ]
          : [{ field: 'createdAt', direction: SortDirection.Desc }]),
      ] as TiersQueryVariables['sorting'],
    [debouncedSearch],
  );

  const { data, isLoading, error } = useQuery(
    ['tiers', filters, pagination, sorting],
    () =>
      sdk
        .tiers({
          ...(Boolean(Object.entries(filters).length) && { filter: filters }),
          paging: pagination,
          sorting,
        })
        .then((res) => res.tiers),
  );

  const tiers = data?.edges?.map(({ node }) => node);

  const createTier = useMutation(
    async (data: any) =>
      sdk
        .createTier({
          input: {
            tier: {
              isPublic: data.isPublic,
              isDefault: data.isDefault,
              name: data.name,
              price: data.price,
              maxEngagements: data.maxEngagements,
            },
          },
        })
        .then((res) => res.createOneTier),
    {
      onMutate: async (newTier) => {
        await queryClient.cancelQueries(['tier', newTier.id]);
        await queryClient.cancelQueries(['tiers', filters]);

        // grab current tier snapshot(s)
        const tierSnapshot = queryClient.getQueryData(['tier', newTier.id]);
        const tiersSnapshot = queryClient.getQueryData(['tiers', filters]);

        queryClient.setQueryData(['tier', newTier.id], newTier);
        queryClient.setQueryData(['tiers', filters], (oldTiers) => ({
          ...(oldTiers as TiersQuery['tiers']),
          edges: [
            {
              node: {
                ...newTier,
                id: '-',
                createdAt: new Date().toISOString(),
              },
            },
            ...(oldTiers as TiersQuery['tiers'])?.edges,
          ],
        }));

        // store snapshots and id in ctx
        return { tiersSnapshot, tierSnapshot, id: newTier.id };
      },
      onError: (error, _, ctx) => {
        toast('Error creating tier', {
          type: 'error',
          position: 'bottom-right',
        });

        if (process.env.NODE_ENV !== 'production') {
          console.error(error);
        }

        queryClient.setQueryData(
          ['tier', (ctx as { id: string }).id],
          (ctx as { tierSnapshot: TierQuery['tier'] }).tierSnapshot,
        );

        queryClient.setQueryData(
          ['tiers', filters],
          (ctx as { tiersSnapshot: TiersQuery['tiers'] }).tiersSnapshot,
        );
      },
      onSuccess: () => {
        toast('Successfully created tier', {
          type: 'success',
          position: 'bottom-right',
        });
        setShowCreateModal(false);
      },
      onSettled: (newTier) => {
        queryClient.invalidateQueries(['tiers', filters]);
        queryClient.invalidateQueries(['tier', newTier?.id]);
        createTier.reset();
      },
    },
  );

  return {
    tiers,
    pageInfo: data?.pageInfo,
    isLoading,
    error,
    createTier,
    showCreateModal,
    toggleCreateModal,
  };
};
