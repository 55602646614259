import { useEffect, useRef, useState } from 'react';
import { Location, History } from 'history';
import { debounce } from 'lodash';
import qs from 'query-string';

type Params = Record<string, any>;

interface UseParamsProps {
  history: History;
  location: Location;
  pagination?: boolean;
  getInitialParams?(urlParams: Params): Params;
}

const updateParams = debounce(
  (params: Params, history: History, pathname: string) => {
    history.replace(
      `${pathname}?${qs.stringify({
        ...params,
      })}`,
    );
  },
  200,
);

export const useParams = ({
  history,
  location,
  getInitialParams,
  pagination,
}: UseParamsProps) => {
  const isFirstRender = useRef(true);
  const urlParams = (qs.parse(location.search) as Params) || {};
  const [params, setParams] = useState<Params>({
    ...(pagination && {
      next: urlParams?.next || undefined,
      prev: urlParams?.prev || undefined,
      pageSize: parseInt(urlParams?.pageSize) || undefined,
    }),
    ...(getInitialParams ? getInitialParams(urlParams) : {}),
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      updateParams(params, history, location.pathname);
    }
  }, [params]);

  const changeParam = (name, value) => {
    setParams({
      ...params,
      [name]: value,
    });
  };

  return { params, changeParam, setParams };
};
