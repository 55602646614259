export default {
  graphqlUrl:
    process.env.REACT_APP_GRAPHQL_URL || 'http://localhost:3001/graphql',
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:3001',
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  seo: {
    defaultTitle: 'Superadmin - Perfect Product Finder',
    meta: [
      {
        name: 'description',
        content: 'Perfect Product Finder. Shopify app by Story of AMS.',
      },
    ],
  },
  transition: { duration: 0.3, ease: [0.42, 0, 0.58, 1] },
};
