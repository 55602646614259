import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Cursor for paging through collections */
  ConnectionCursor: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** URL scalar type */
  URL: any;
  /** UUID v4 scalar type */
  UUID: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ActivateFlowInput = {
  flowId: Scalars['UUID'];
};

export type ActiveFlow = {
  __typename?: 'ActiveFlow';
  colorScheme: FlowColorScheme;
  enableAddToCart: Scalars['Boolean'];
  enableMoreResults: Scalars['Boolean'];
  enableRestart: Scalars['Boolean'];
  enableReviews?: Maybe<Scalars['Boolean']>;
  fontFamily?: Maybe<Scalars['String']>;
  hasProgressBar: Scalars['Boolean'];
  hasStepIndicator: Scalars['Boolean'];
  id: Scalars['String'];
  locale: FlowLocale;
  logo?: Maybe<File>;
  nodes: Array<ActiveFlowNode>;
  primaryColor?: Maybe<Scalars['String']>;
  resultsAmount: Scalars['Float'];
  resultsDescription?: Maybe<Scalars['String']>;
  resultsTitle?: Maybe<Scalars['String']>;
  status: FlowStatus;
  translation?: Maybe<ActiveTranslation>;
};

export type ActiveFlowContainer = {
  __typename?: 'ActiveFlowContainer';
  flow: ActiveFlow;
  id: Scalars['String'];
  name: Scalars['String'];
  ogImage?: Maybe<File>;
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type ActiveFlowNode = {
  __typename?: 'ActiveFlowNode';
  description?: Maybe<Scalars['String']>;
  distanceToEnd?: Maybe<GraphMinMaxDistance>;
  id: Scalars['String'];
  image?: Maybe<FileUnion>;
  isRequired: Scalars['Boolean'];
  layout: FlowNodeLayout;
  options: Array<ActiveFlowNodeOption>;
  title?: Maybe<Scalars['String']>;
  type: FlowNodeType;
  video?: Maybe<UrlFile>;
};

export type ActiveFlowNodeOption = {
  __typename?: 'ActiveFlowNodeOption';
  description?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<FileUnion>;
  label: Scalars['String'];
  nextAction: FlowNodeOptionNextAction;
  nextNode?: Maybe<Scalars['ID']>;
};

export type ActiveTranslation = {
  __typename?: 'ActiveTranslation';
  id: Scalars['String'];
  isRtl: Scalars['Boolean'];
  locale: Scalars['String'];
  messages?: Maybe<Array<KeyStringValuePair>>;
};

export type AddSelectionInput = {
  nodeId: Scalars['UUID'];
  /** Not sending an option ID means the question has been skipped. */
  optionId?: Maybe<Scalars['UUID']>;
  sessionId: Scalars['UUID'];
};

export type AdminSession = {
  __typename?: 'AdminSession';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type AnalyticsConversionRate = {
  __typename?: 'AnalyticsConversionRate';
  converted: AnalyticsConversionRateItem;
  engagements: AnalyticsConversionRateItem;
  reachedResults: AnalyticsConversionRateItem;
  sessions: AnalyticsConversionRateItem;
};

export type AnalyticsConversionRateInput = {
  end: Scalars['DateTime'];
  flowContainer?: Maybe<Scalars['UUID']>;
  start: Scalars['DateTime'];
};

export type AnalyticsConversionRateItem = {
  __typename?: 'AnalyticsConversionRateItem';
  changePercentage?: Maybe<Scalars['Float']>;
  count: Scalars['Float'];
  percentage: Scalars['Float'];
};

export type AnalyticsFilterInput = {
  compareTo?: Maybe<CompareTo>;
  end: Scalars['DateTime'];
  flowContainer?: Maybe<Scalars['UUID']>;
  start: Scalars['DateTime'];
};

export type AnalyticsFlowContainerFilterInput = {
  end: Scalars['DateTime'];
  flowContainer: Scalars['UUID'];
  start: Scalars['DateTime'];
};

export type AnalyticsResult = {
  __typename?: 'AnalyticsResult';
  date: Scalars['String'];
  items: Array<AnalyticsResultItem>;
};

export type AnalyticsResultItem = {
  __typename?: 'AnalyticsResultItem';
  key: Scalars['String'];
  value: Scalars['Float'];
};

export type AnalyticsTopProduct = {
  __typename?: 'AnalyticsTopProduct';
  changePercentage?: Maybe<Scalars['Float']>;
  count: Scalars['Float'];
  productId: Scalars['String'];
  productName: Scalars['String'];
};

export type AnalyticsTopRecommendedProduct = {
  __typename?: 'AnalyticsTopRecommendedProduct';
  changePercentage?: Maybe<Scalars['Float']>;
  productId: Scalars['String'];
  productName: Scalars['String'];
  recommendationCount: Scalars['Float'];
  recommendationPercentage: Scalars['Float'];
};

export type AnalyticsUsage = {
  __typename?: 'AnalyticsUsage';
  engagements: Scalars['Float'];
};

export type AnalyticsUsageFilterInput = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type BooleanFieldComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
};

export enum CollectionSortKeys {
  Id = 'Id',
  Relevance = 'Relevance',
  Title = 'Title',
  UpdatedAt = 'UpdatedAt',
}

export enum CompareTo {
  PreviousPeriod = 'PREVIOUS_PERIOD',
}

export type CreateFlowContainerInput = {
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type CreateFlowInput = {
  containerId: Scalars['UUID'];
};

export type CreateFlowNodeInput = {
  description?: Maybe<Scalars['String']>;
  flowId: Scalars['String'];
  isRequired: Scalars['Boolean'];
  layout: FlowNodeLayout;
  options?: Maybe<Array<FlowNodeOptionInput>>;
  title?: Maybe<Scalars['String']>;
  type: FlowNodeType;
};

export type CreateIntegrationInput = {
  privateKey?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  type: IntegrationType;
};

export type CreateOneFlowContainerInput = {
  /** The record to create */
  flowContainer: CreateFlowContainerInput;
};

export type CreateOneFlowInput = {
  /** The record to create */
  flow: CreateFlowInput;
};

export type CreateOneFlowNodeInput = {
  /** The record to create */
  flowNode: CreateFlowNodeInput;
};

export type CreateOneIntegrationInput = {
  /** The record to create */
  integration: CreateIntegrationInput;
};

export type CreateOneTierInput = {
  /** The record to create */
  tier: CreateTier;
};

export type CreateOneTranslationInput = {
  /** The record to create */
  translation: CreateTranslation;
};

export type CreateTier = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  maxEngagements?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  /** Price in cents. */
  price?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateTranslation = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRtl?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  messages?: Maybe<Array<KeyStringValuePairInput>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum CurrencyCode {
  Aed = 'Aed',
  Afn = 'Afn',
  All = 'All',
  Amd = 'Amd',
  Ang = 'Ang',
  Aoa = 'Aoa',
  Ars = 'Ars',
  Aud = 'Aud',
  Awg = 'Awg',
  Azn = 'Azn',
  Bam = 'Bam',
  Bbd = 'Bbd',
  Bdt = 'Bdt',
  Bgn = 'Bgn',
  Bhd = 'Bhd',
  Bif = 'Bif',
  Bmd = 'Bmd',
  Bnd = 'Bnd',
  Bob = 'Bob',
  Brl = 'Brl',
  Bsd = 'Bsd',
  Btn = 'Btn',
  Bwp = 'Bwp',
  Byn = 'Byn',
  Byr = 'Byr',
  Bzd = 'Bzd',
  Cad = 'Cad',
  Cdf = 'Cdf',
  Chf = 'Chf',
  Clp = 'Clp',
  Cny = 'Cny',
  Cop = 'Cop',
  Crc = 'Crc',
  Cve = 'Cve',
  Czk = 'Czk',
  Djf = 'Djf',
  Dkk = 'Dkk',
  Dop = 'Dop',
  Dzd = 'Dzd',
  Egp = 'Egp',
  Ern = 'Ern',
  Etb = 'Etb',
  Eur = 'Eur',
  Fjd = 'Fjd',
  Fkp = 'Fkp',
  Gbp = 'Gbp',
  Gel = 'Gel',
  Ghs = 'Ghs',
  Gip = 'Gip',
  Gmd = 'Gmd',
  Gnf = 'Gnf',
  Gtq = 'Gtq',
  Gyd = 'Gyd',
  Hkd = 'Hkd',
  Hnl = 'Hnl',
  Hrk = 'Hrk',
  Htg = 'Htg',
  Huf = 'Huf',
  Idr = 'Idr',
  Ils = 'Ils',
  Inr = 'Inr',
  Iqd = 'Iqd',
  Irr = 'Irr',
  Isk = 'Isk',
  Jep = 'Jep',
  Jmd = 'Jmd',
  Jod = 'Jod',
  Jpy = 'Jpy',
  Kes = 'Kes',
  Kgs = 'Kgs',
  Khr = 'Khr',
  Kid = 'Kid',
  Kmf = 'Kmf',
  Krw = 'Krw',
  Kwd = 'Kwd',
  Kyd = 'Kyd',
  Kzt = 'Kzt',
  Lak = 'Lak',
  Lbp = 'Lbp',
  Lkr = 'Lkr',
  Lrd = 'Lrd',
  Lsl = 'Lsl',
  Ltl = 'Ltl',
  Lvl = 'Lvl',
  Lyd = 'Lyd',
  Mad = 'Mad',
  Mdl = 'Mdl',
  Mga = 'Mga',
  Mkd = 'Mkd',
  Mmk = 'Mmk',
  Mnt = 'Mnt',
  Mop = 'Mop',
  Mru = 'Mru',
  Mur = 'Mur',
  Mvr = 'Mvr',
  Mwk = 'Mwk',
  Mxn = 'Mxn',
  Myr = 'Myr',
  Mzn = 'Mzn',
  Nad = 'Nad',
  Ngn = 'Ngn',
  Nio = 'Nio',
  Nok = 'Nok',
  Npr = 'Npr',
  Nzd = 'Nzd',
  Omr = 'Omr',
  Pab = 'Pab',
  Pen = 'Pen',
  Pgk = 'Pgk',
  Php = 'Php',
  Pkr = 'Pkr',
  Pln = 'Pln',
  Pyg = 'Pyg',
  Qar = 'Qar',
  Ron = 'Ron',
  Rsd = 'Rsd',
  Rub = 'Rub',
  Rwf = 'Rwf',
  Sar = 'Sar',
  Sbd = 'Sbd',
  Scr = 'Scr',
  Sdg = 'Sdg',
  Sek = 'Sek',
  Sgd = 'Sgd',
  Shp = 'Shp',
  Sll = 'Sll',
  Sos = 'Sos',
  Srd = 'Srd',
  Ssp = 'Ssp',
  Std = 'Std',
  Syp = 'Syp',
  Szl = 'Szl',
  Thb = 'Thb',
  Tjs = 'Tjs',
  Tmt = 'Tmt',
  Tnd = 'Tnd',
  Top = 'Top',
  Try = 'Try',
  Ttd = 'Ttd',
  Twd = 'Twd',
  Tzs = 'Tzs',
  Uah = 'Uah',
  Ugx = 'Ugx',
  Usd = 'Usd',
  Uyu = 'Uyu',
  Uzs = 'Uzs',
  Vef = 'Vef',
  Ves = 'Ves',
  Vnd = 'Vnd',
  Vuv = 'Vuv',
  Wst = 'Wst',
  Xaf = 'Xaf',
  Xcd = 'Xcd',
  Xof = 'Xof',
  Xpf = 'Xpf',
  Yer = 'Yer',
  Zar = 'Zar',
  Zmw = 'Zmw',
}

export type CursorPaging = {
  /** Paginate after opaque cursor */
  after?: Maybe<Scalars['ConnectionCursor']>;
  /** Paginate before opaque cursor */
  before?: Maybe<Scalars['ConnectionCursor']>;
  /** Paginate first */
  first?: Maybe<Scalars['Int']>;
  /** Paginate last */
  last?: Maybe<Scalars['Int']>;
};

export type DateFieldComparison = {
  between?: Maybe<DateFieldComparisonBetween>;
  eq?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  notBetween?: Maybe<DateFieldComparisonBetween>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime'];
  upper: Scalars['DateTime'];
};

export type DeleteManyFlowContainersInput = {
  /** Filter to find records to delete */
  filter: FlowContainerDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  /** The number of records deleted. */
  deletedCount: Scalars['Int'];
};

export type DeleteOneInput = {
  /** The id of the record to delete. */
  id: Scalars['ID'];
};

export type EndSessionInput = {
  email?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

export type File = {
  __typename?: 'File';
  directory: Scalars['String'];
  fileName: Scalars['String'];
  storage: StorageType;
};

export type FileUnion = File | UrlFile;

export type Flow = {
  __typename?: 'Flow';
  amountOfSteps: Scalars['Float'];
  colorScheme: FlowColorScheme;
  container: FlowContainer;
  createdAt: Scalars['DateTime'];
  enableAddToCart: Scalars['Boolean'];
  enableMoreResults: Scalars['Boolean'];
  enableRestart: Scalars['Boolean'];
  enableReviews?: Maybe<Scalars['Boolean']>;
  fontFamily?: Maybe<Scalars['String']>;
  hasProgressBar: Scalars['Boolean'];
  hasStepIndicator: Scalars['Boolean'];
  id: Scalars['String'];
  locale: FlowLocale;
  logo?: Maybe<File>;
  nodes: Array<FlowNode>;
  previousVersion?: Maybe<Flow>;
  primaryColor?: Maybe<Scalars['String']>;
  productIds: Array<Scalars['String']>;
  resultsAmount: Scalars['Float'];
  resultsDescription?: Maybe<Scalars['String']>;
  resultsTitle?: Maybe<Scalars['String']>;
  status: FlowStatus;
  translationId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type FlowNodesArgs = {
  filter?: Maybe<FlowNodeFilter>;
  sorting?: Maybe<Array<FlowNodeSort>>;
};

export type FlowAggregateGroupBy = {
  __typename?: 'FlowAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<FlowStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Flow color scheme. */
export enum FlowColorScheme {
  Cool = 'COOL',
  Normal = 'NORMAL',
  Warm = 'WARM',
}

export type FlowContainer = {
  __typename?: 'FlowContainer';
  createdAt: Scalars['DateTime'];
  flows: Array<Flow>;
  id: Scalars['String'];
  integrationMetadata?: Maybe<FlowIntegrationMetadata>;
  name: Scalars['String'];
  ogImage?: Maybe<File>;
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status: FlowContainerStatus;
  updatedAt: Scalars['DateTime'];
};

export type FlowContainerFlowsArgs = {
  filter?: Maybe<FlowFilter>;
  sorting?: Maybe<Array<FlowSort>>;
};

export type FlowContainerAggregateGroupBy = {
  __typename?: 'FlowContainerAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<FlowContainerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FlowContainerConnection = {
  __typename?: 'FlowContainerConnection';
  /** Array of edges. */
  edges: Array<FlowContainerEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type FlowContainerCountAggregate = {
  __typename?: 'FlowContainerCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type FlowContainerDeleteFilter = {
  and?: Maybe<Array<FlowContainerDeleteFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<FlowContainerDeleteFilter>>;
  status?: Maybe<FlowContainerStatusFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type FlowContainerDeleteResponse = {
  __typename?: 'FlowContainerDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  integrationMetadata?: Maybe<FlowIntegrationMetadata>;
  name?: Maybe<Scalars['String']>;
  ogImage?: Maybe<File>;
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<FlowContainerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FlowContainerEdge = {
  __typename?: 'FlowContainerEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the FlowContainer */
  node: FlowContainer;
};

export type FlowContainerFilter = {
  and?: Maybe<Array<FlowContainerFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<FlowContainerFilter>>;
  status?: Maybe<FlowContainerStatusFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type FlowContainerMaxAggregate = {
  __typename?: 'FlowContainerMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<FlowContainerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FlowContainerMinAggregate = {
  __typename?: 'FlowContainerMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<FlowContainerStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FlowContainerSort = {
  direction: SortDirection;
  field: FlowContainerSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum FlowContainerSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

/** Flow container status. */
export enum FlowContainerStatus {
  Archived = 'ARCHIVED',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type FlowContainerStatusFilterComparison = {
  eq?: Maybe<FlowContainerStatus>;
  gt?: Maybe<FlowContainerStatus>;
  gte?: Maybe<FlowContainerStatus>;
  iLike?: Maybe<FlowContainerStatus>;
  in?: Maybe<Array<FlowContainerStatus>>;
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  like?: Maybe<FlowContainerStatus>;
  lt?: Maybe<FlowContainerStatus>;
  lte?: Maybe<FlowContainerStatus>;
  neq?: Maybe<FlowContainerStatus>;
  notILike?: Maybe<FlowContainerStatus>;
  notIn?: Maybe<Array<FlowContainerStatus>>;
  notLike?: Maybe<FlowContainerStatus>;
};

export type FlowContainerUpdateFilter = {
  and?: Maybe<Array<FlowContainerUpdateFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<FlowContainerUpdateFilter>>;
  status?: Maybe<FlowContainerStatusFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type FlowCountAggregate = {
  __typename?: 'FlowCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type FlowEdge = {
  __typename?: 'FlowEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Flow */
  node: Flow;
};

export type FlowFilter = {
  and?: Maybe<Array<FlowFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<FlowFilter>>;
  status?: Maybe<FlowStatusFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type FlowIntegrationMetadata = {
  __typename?: 'FlowIntegrationMetadata';
  klaviyo?: Maybe<FlowIntegrationMetadataKlaviyo>;
  mailchimp?: Maybe<FlowIntegrationMetadataMailchimp>;
};

export type FlowIntegrationMetadataInput = {
  klaviyo?: Maybe<FlowIntegrationMetadataKlaviyoInput>;
  mailchimp?: Maybe<FlowIntegrationMetadataMailchimpInput>;
};

export type FlowIntegrationMetadataKlaviyo = {
  __typename?: 'FlowIntegrationMetadataKlaviyo';
  listId: Scalars['String'];
};

export type FlowIntegrationMetadataKlaviyoInput = {
  listId: Scalars['String'];
};

export type FlowIntegrationMetadataMailchimp = {
  __typename?: 'FlowIntegrationMetadataMailchimp';
  listId: Scalars['String'];
};

export type FlowIntegrationMetadataMailchimpInput = {
  listId: Scalars['String'];
};

/** Flow locale. */
export enum FlowLocale {
  Custom = 'CUSTOM',
  En = 'EN',
  Nl = 'NL',
}

export type FlowMaxAggregate = {
  __typename?: 'FlowMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<FlowStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FlowMinAggregate = {
  __typename?: 'FlowMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<FlowStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FlowNode = {
  __typename?: 'FlowNode';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  flowId: Scalars['String'];
  id: Scalars['String'];
  image?: Maybe<FileUnion>;
  isRequired: Scalars['Boolean'];
  layout: FlowNodeLayout;
  options?: Maybe<Array<FlowNodeOption>>;
  order: Scalars['Float'];
  title?: Maybe<Scalars['String']>;
  type: FlowNodeType;
  updatedAt: Scalars['DateTime'];
  video?: Maybe<UrlFile>;
};

export type FlowNodeAggregateGroupBy = {
  __typename?: 'FlowNodeAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FlowNodeAvgAggregate = {
  __typename?: 'FlowNodeAvgAggregate';
  order?: Maybe<Scalars['Float']>;
};

export type FlowNodeCountAggregate = {
  __typename?: 'FlowNodeCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type FlowNodeDeleteResponse = {
  __typename?: 'FlowNodeDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<FileUnion>;
  isRequired?: Maybe<Scalars['Boolean']>;
  layout?: Maybe<FlowNodeLayout>;
  options?: Maybe<Array<FlowNodeOption>>;
  order?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<FlowNodeType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  video?: Maybe<UrlFile>;
};

export type FlowNodeEdge = {
  __typename?: 'FlowNodeEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the FlowNode */
  node: FlowNode;
};

export type FlowNodeFilter = {
  and?: Maybe<Array<FlowNodeFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<FlowNodeFilter>>;
  order?: Maybe<NumberFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export enum FlowNodeLayout {
  MediaCoverLeft = 'MEDIA_COVER_LEFT',
  MediaCoverRight = 'MEDIA_COVER_RIGHT',
  MediaFloatLeft = 'MEDIA_FLOAT_LEFT',
  MediaFloatRight = 'MEDIA_FLOAT_RIGHT',
  MediaFloatTop = 'MEDIA_FLOAT_TOP',
  Simple = 'SIMPLE',
}

export type FlowNodeMaxAggregate = {
  __typename?: 'FlowNodeMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FlowNodeMinAggregate = {
  __typename?: 'FlowNodeMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FlowNodeOption = {
  __typename?: 'FlowNodeOption';
  description?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<FileUnion>;
  label: Scalars['String'];
  nextAction: FlowNodeOptionNextAction;
  nextNode?: Maybe<Scalars['ID']>;
  productIds: Array<Scalars['String']>;
};

export type FlowNodeOptionInput = {
  description?: Maybe<Scalars['String']>;
  emoji?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<UrlFileInput>;
  label: Scalars['String'];
  productIds?: Maybe<Array<Scalars['String']>>;
};

export type FlowNodeOptionLogicInput = {
  id: Scalars['ID'];
  nextAction?: Maybe<FlowNodeOptionNextAction>;
  nextNode?: Maybe<Scalars['ID']>;
};

/** Option next action. */
export enum FlowNodeOptionNextAction {
  Auto = 'AUTO',
  EndSession = 'END_SESSION',
  SpecificNode = 'SPECIFIC_NODE',
}

export type FlowNodeOrderInput = {
  flowId: Scalars['UUID'];
  order: Array<Scalars['UUID']>;
};

export type FlowNodeSort = {
  direction: SortDirection;
  field: FlowNodeSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum FlowNodeSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Order = 'order',
  UpdatedAt = 'updatedAt',
}

export type FlowNodeSumAggregate = {
  __typename?: 'FlowNodeSumAggregate';
  order?: Maybe<Scalars['Float']>;
};

export enum FlowNodeType {
  Email = 'EMAIL',
  Emoji = 'EMOJI',
  Image = 'IMAGE',
  Simple = 'SIMPLE',
  Welcome = 'WELCOME',
}

export type FlowNodeUploadInput = {
  image: Scalars['Upload'];
  nodeId: Scalars['UUID'];
};

export type FlowSort = {
  direction: SortDirection;
  field: FlowSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum FlowSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

/** Flow status. */
export enum FlowStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE',
}

export type FlowStatusFilterComparison = {
  eq?: Maybe<FlowStatus>;
  gt?: Maybe<FlowStatus>;
  gte?: Maybe<FlowStatus>;
  iLike?: Maybe<FlowStatus>;
  in?: Maybe<Array<FlowStatus>>;
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  like?: Maybe<FlowStatus>;
  lt?: Maybe<FlowStatus>;
  lte?: Maybe<FlowStatus>;
  neq?: Maybe<FlowStatus>;
  notILike?: Maybe<FlowStatus>;
  notIn?: Maybe<Array<FlowStatus>>;
  notLike?: Maybe<FlowStatus>;
};

export type FlowUploadInput = {
  flowId: Scalars['UUID'];
  logo: Scalars['Upload'];
};

export type GraphMinMaxDistance = {
  __typename?: 'GraphMinMaxDistance';
  max: Scalars['Float'];
  min: Scalars['Float'];
};

export type Integration = {
  __typename?: 'Integration';
  createdAt: Scalars['DateTime'];
  hasPrivateKey: Scalars['Boolean'];
  id: Scalars['String'];
  publicKey?: Maybe<Scalars['String']>;
  status: IntegrationStatus;
  type: IntegrationType;
  updatedAt: Scalars['DateTime'];
};

export type IntegrationAggregateGroupBy = {
  __typename?: 'IntegrationAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<IntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IntegrationConnection = {
  __typename?: 'IntegrationConnection';
  /** Array of edges. */
  edges: Array<IntegrationEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type IntegrationCountAggregate = {
  __typename?: 'IntegrationCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type IntegrationDeleteResponse = {
  __typename?: 'IntegrationDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  status?: Maybe<IntegrationStatus>;
  type?: Maybe<IntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IntegrationEdge = {
  __typename?: 'IntegrationEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Integration */
  node: Integration;
};

export type IntegrationFilter = {
  and?: Maybe<Array<IntegrationFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<IntegrationFilter>>;
  type?: Maybe<IntegrationTypeFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type IntegrationMaxAggregate = {
  __typename?: 'IntegrationMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<IntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IntegrationMinAggregate = {
  __typename?: 'IntegrationMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<IntegrationType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IntegrationSort = {
  direction: SortDirection;
  field: IntegrationSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum IntegrationSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Type = 'type',
  UpdatedAt = 'updatedAt',
}

/** Integration status. */
export enum IntegrationStatus {
  Connected = 'CONNECTED',
  Incomplete = 'INCOMPLETE',
  Invalid = 'INVALID',
}

export enum IntegrationType {
  Klaviyo = 'KLAVIYO',
  Mailchimp = 'MAILCHIMP',
}

export type IntegrationTypeFilterComparison = {
  eq?: Maybe<IntegrationType>;
  gt?: Maybe<IntegrationType>;
  gte?: Maybe<IntegrationType>;
  iLike?: Maybe<IntegrationType>;
  in?: Maybe<Array<IntegrationType>>;
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  like?: Maybe<IntegrationType>;
  lt?: Maybe<IntegrationType>;
  lte?: Maybe<IntegrationType>;
  neq?: Maybe<IntegrationType>;
  notILike?: Maybe<IntegrationType>;
  notIn?: Maybe<Array<IntegrationType>>;
  notLike?: Maybe<IntegrationType>;
};

export type KeyKvpArrayValuePair = {
  __typename?: 'KeyKVPArrayValuePair';
  key: Scalars['String'];
  value: Array<KeyNumberValuePair>;
};

export type KeyKvpArrayValuePairInput = {
  key: Scalars['String'];
  value: Array<KeyNumberValuePairInput>;
};

export type KeyNumberValuePair = {
  __typename?: 'KeyNumberValuePair';
  key: Scalars['String'];
  value: Scalars['Float'];
};

export type KeyNumberValuePairInput = {
  key: Scalars['String'];
  value: Scalars['Float'];
};

export type KeyStringValuePair = {
  __typename?: 'KeyStringValuePair';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KeyStringValuePairInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateFlow: Flow;
  /** Submits an answer to a question in the flow. */
  addSelection: Session;
  authenticateAdmin: AdminSession;
  createOneFlow: Flow;
  createOneFlowContainer: FlowContainer;
  createOneFlowNode: FlowNode;
  createOneIntegration: Integration;
  createOneTier: Tier;
  createOneTranslation: Translation;
  deleteManyFlowContainers: DeleteManyResponse;
  deleteOneFlowContainer: FlowContainerDeleteResponse;
  deleteOneFlowNode: FlowNodeDeleteResponse;
  deleteOneIntegration: IntegrationDeleteResponse;
  deleteOneStore: StoreDeleteResponse;
  deleteOneTranslation: TranslationDeleteResponse;
  /** Ends a session if all questions have been answered. */
  endSession: Session;
  logoutAdmin: Scalars['Boolean'];
  publishMessage: Scalars['String'];
  /** Removes an answer from session (when skip is used). */
  removeSelection: Session;
  /** Restarts an ended session and returns the newly created session. */
  restartSession: Session;
  setDefaultTier: Scalars['Boolean'];
  shopifyCreateSubscription: Scalars['String'];
  /** Starts a new session. */
  startSession: Session;
  updateFlowContainerOgImage: FlowContainer;
  updateFlowLogic: Flow;
  updateFlowNodesOrder: Array<FlowNode>;
  updateManyFlowContainers: UpdateManyResponse;
  updateOneFlow: Flow;
  updateOneFlowContainer: FlowContainer;
  updateOneFlowNode: FlowNode;
  updateOneIntegration: Integration;
  updateOneStore: Store;
  updateOneTier: Tier;
  updateOneTranslation: Translation;
  updateUrlFileForOption: FlowNodeOption;
  uploadFileForOption: FlowNodeOption;
  uploadImageForFlowNode: FlowNode;
  uploadLogoForFlow: Flow;
};

export type MutationActivateFlowArgs = {
  input: ActivateFlowInput;
};

export type MutationAddSelectionArgs = {
  input: AddSelectionInput;
};

export type MutationAuthenticateAdminArgs = {
  token: Scalars['String'];
};

export type MutationCreateOneFlowArgs = {
  input: CreateOneFlowInput;
};

export type MutationCreateOneFlowContainerArgs = {
  input: CreateOneFlowContainerInput;
};

export type MutationCreateOneFlowNodeArgs = {
  input: CreateOneFlowNodeInput;
};

export type MutationCreateOneIntegrationArgs = {
  input: CreateOneIntegrationInput;
};

export type MutationCreateOneTierArgs = {
  input: CreateOneTierInput;
};

export type MutationCreateOneTranslationArgs = {
  input: CreateOneTranslationInput;
};

export type MutationDeleteManyFlowContainersArgs = {
  input: DeleteManyFlowContainersInput;
};

export type MutationDeleteOneFlowContainerArgs = {
  input: DeleteOneInput;
};

export type MutationDeleteOneFlowNodeArgs = {
  input: DeleteOneInput;
};

export type MutationDeleteOneIntegrationArgs = {
  input: DeleteOneInput;
};

export type MutationDeleteOneStoreArgs = {
  input: DeleteOneInput;
};

export type MutationDeleteOneTranslationArgs = {
  input: DeleteOneInput;
};

export type MutationEndSessionArgs = {
  input: EndSessionInput;
};

export type MutationPublishMessageArgs = {
  attributes?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  topic: Scalars['String'];
};

export type MutationRemoveSelectionArgs = {
  input: RemoveSelectionInput;
};

export type MutationRestartSessionArgs = {
  id: Scalars['String'];
};

export type MutationSetDefaultTierArgs = {
  id: Scalars['String'];
};

export type MutationShopifyCreateSubscriptionArgs = {
  input: ShopifyCreateSubscriptionInput;
};

export type MutationStartSessionArgs = {
  input: StartSessionInput;
};

export type MutationUpdateFlowContainerOgImageArgs = {
  input: UploadFlowContainerOgImageInput;
};

export type MutationUpdateFlowLogicArgs = {
  input: UpdateFlowLogicInput;
};

export type MutationUpdateFlowNodesOrderArgs = {
  input: FlowNodeOrderInput;
};

export type MutationUpdateManyFlowContainersArgs = {
  input: UpdateManyFlowContainersInput;
};

export type MutationUpdateOneFlowArgs = {
  input: UpdateOneFlowInput;
};

export type MutationUpdateOneFlowContainerArgs = {
  input: UpdateOneFlowContainerInput;
};

export type MutationUpdateOneFlowNodeArgs = {
  input: UpdateOneFlowNodeInput;
};

export type MutationUpdateOneIntegrationArgs = {
  input: UpdateOneIntegrationInput;
};

export type MutationUpdateOneStoreArgs = {
  input: UpdateOneStoreInput;
};

export type MutationUpdateOneTierArgs = {
  input: UpdateOneTierInput;
};

export type MutationUpdateOneTranslationArgs = {
  input: UpdateOneTranslationInput;
};

export type MutationUpdateUrlFileForOptionArgs = {
  input: UpdateFlowNodeOptionImageInput;
};

export type MutationUploadFileForOptionArgs = {
  input: UploadFlowNodeOptionImageInput;
};

export type MutationUploadImageForFlowNodeArgs = {
  input: FlowNodeUploadInput;
};

export type MutationUploadLogoForFlowArgs = {
  input: FlowUploadInput;
};

export type NumberFieldComparison = {
  between?: Maybe<NumberFieldComparisonBetween>;
  eq?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  neq?: Maybe<Scalars['Float']>;
  notBetween?: Maybe<NumberFieldComparisonBetween>;
  notIn?: Maybe<Array<Scalars['Float']>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float'];
  upper: Scalars['Float'];
};

export enum OttAction {
  ExportEmails = 'EXPORT_EMAILS',
  GenerateJwt = 'GENERATE_JWT',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']>;
};

export enum ProductCollectionSortKeys {
  BestSelling = 'BestSelling',
  CollectionDefault = 'CollectionDefault',
  Created = 'Created',
  Id = 'Id',
  Manual = 'Manual',
  Price = 'Price',
  Relevance = 'Relevance',
  Title = 'Title',
}

export enum ProductSortKeys {
  CreatedAt = 'CreatedAt',
  Id = 'Id',
  InventoryTotal = 'InventoryTotal',
  ProductType = 'ProductType',
  PublishedAt = 'PublishedAt',
  Relevance = 'Relevance',
  Title = 'Title',
  UpdatedAt = 'UpdatedAt',
  Vendor = 'Vendor',
}

export type Query = {
  __typename?: 'Query';
  /** Get one time token to use in `authenticateShopify` Query. */
  accessToken: Scalars['String'];
  activeFlowContainer?: Maybe<ActiveFlowContainer>;
  /** Returns `null` if not authenticated. */
  adminSession?: Maybe<AdminSession>;
  analyticsRevenueAverageOrderValue: Array<AnalyticsResult>;
  analyticsRevenueOrders: Array<AnalyticsResult>;
  analyticsRevenueSales: Array<AnalyticsResult>;
  analyticsSessions: Array<AnalyticsResult>;
  analyticsSessionsPerCountry: Array<KeyKvpArrayValuePair>;
  analyticsSessionsPerDevice: Array<KeyKvpArrayValuePair>;
  analyticsTopProducts: Array<AnalyticsTopProduct>;
  analyticsTopRecommended: Array<AnalyticsTopRecommendedProduct>;
  analyticsUsage: AnalyticsUsage;
  /** Set Shopify JWT cookie to authenticate outside of Shopify. */
  authenticateShopify: Scalars['String'];
  conversionRates: AnalyticsConversionRate;
  /** Fetches the current store. */
  currentStore?: Maybe<Store>;
  flow?: Maybe<Flow>;
  flowContainer?: Maybe<FlowContainer>;
  flowContainers: FlowContainerConnection;
  flowNode?: Maybe<FlowNode>;
  integration?: Maybe<Integration>;
  integrations: IntegrationConnection;
  previewFlowContainer?: Maybe<ActiveFlowContainer>;
  session?: Maybe<Session>;
  sessions: SessionConnection;
  shopifyCollections: ShopifyCollectionConnection;
  shopifyProductTypes: ShopifyStringConnection;
  shopifyProducts: ShopifyProductConnection;
  shopifyProductsByCollection: ShopifyProductConnection;
  shopifyProductsByIds: Array<ShopifyProduct>;
  store?: Maybe<Store>;
  storeEngagements: Array<StoreEngagementOutput>;
  stores: StoreConnection;
  tier?: Maybe<Tier>;
  tiers: TierConnection;
  translation?: Maybe<Translation>;
  translations: TranslationConnection;
};

export type QueryAccessTokenArgs = {
  action?: Maybe<OttAction>;
};

export type QueryActiveFlowContainerArgs = {
  slug: Scalars['String'];
};

export type QueryAnalyticsRevenueAverageOrderValueArgs = {
  input: AnalyticsFilterInput;
};

export type QueryAnalyticsRevenueOrdersArgs = {
  input: AnalyticsFilterInput;
};

export type QueryAnalyticsRevenueSalesArgs = {
  input: AnalyticsFilterInput;
};

export type QueryAnalyticsSessionsArgs = {
  input: AnalyticsFilterInput;
};

export type QueryAnalyticsSessionsPerCountryArgs = {
  input: AnalyticsFlowContainerFilterInput;
};

export type QueryAnalyticsSessionsPerDeviceArgs = {
  input: AnalyticsFlowContainerFilterInput;
};

export type QueryAnalyticsTopProductsArgs = {
  input: AnalyticsFilterInput;
};

export type QueryAnalyticsTopRecommendedArgs = {
  input: AnalyticsFilterInput;
};

export type QueryAnalyticsUsageArgs = {
  input: AnalyticsUsageFilterInput;
};

export type QueryAuthenticateShopifyArgs = {
  token: Scalars['String'];
};

export type QueryConversionRatesArgs = {
  input: AnalyticsConversionRateInput;
};

export type QueryFlowArgs = {
  id: Scalars['ID'];
};

export type QueryFlowContainerArgs = {
  id: Scalars['ID'];
};

export type QueryFlowContainersArgs = {
  filter?: Maybe<FlowContainerFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<FlowContainerSort>>;
};

export type QueryFlowNodeArgs = {
  id: Scalars['ID'];
};

export type QueryIntegrationArgs = {
  id: Scalars['ID'];
};

export type QueryIntegrationsArgs = {
  filter?: Maybe<IntegrationFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<IntegrationSort>>;
};

export type QueryPreviewFlowContainerArgs = {
  flowId: Scalars['String'];
  slug: Scalars['String'];
};

export type QuerySessionArgs = {
  id: Scalars['ID'];
};

export type QuerySessionsArgs = {
  filter?: Maybe<SessionFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<SessionSort>>;
};

export type QueryShopifyCollectionsArgs = {
  input: ShopifyCollectionInput;
};

export type QueryShopifyProductTypesArgs = {
  input: ShopifyProductTypesInput;
};

export type QueryShopifyProductsArgs = {
  input: ShopifyProductsInput;
};

export type QueryShopifyProductsByCollectionArgs = {
  input: ShopifyCollectionByHandleInput;
};

export type QueryShopifyProductsByIdsArgs = {
  input: ShopifyProductsByIdsInput;
};

export type QueryStoreArgs = {
  id: Scalars['ID'];
};

export type QueryStoreEngagementsArgs = {
  input: StoreEngagementFilterInput;
};

export type QueryStoresArgs = {
  filter?: Maybe<StoreFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<StoreSort>>;
};

export type QueryTierArgs = {
  id: Scalars['ID'];
};

export type QueryTiersArgs = {
  filter?: Maybe<TierFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<TierSort>>;
};

export type QueryTranslationArgs = {
  id: Scalars['ID'];
};

export type QueryTranslationsArgs = {
  filter?: Maybe<TranslationFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<TranslationSort>>;
};

export type RemoveSelectionInput = {
  nodeId: Scalars['UUID'];
  sessionId: Scalars['UUID'];
};

export type Session = {
  __typename?: 'Session';
  city?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  convertedAt?: Maybe<Scalars['DateTime']>;
  convertedValue?: Maybe<Scalars['Float']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  flowId: Scalars['String'];
  id: Scalars['String'];
  isTest: Scalars['Boolean'];
  language?: Maybe<Scalars['String']>;
  orderCurrency?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  orderName?: Maybe<Scalars['String']>;
  originalSessionId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  /** Get the sorted results of an ended session. */
  results?: Maybe<Array<SessionResult>>;
  selections: Array<SessionSelection>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status: SessionStatus;
  totalOrderValue?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
};

export type SessionAggregateGroupBy = {
  __typename?: 'SessionAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isTest?: Maybe<Scalars['Boolean']>;
  orderName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SessionConnection = {
  __typename?: 'SessionConnection';
  /** Array of edges. */
  edges: Array<SessionEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type SessionCountAggregate = {
  __typename?: 'SessionCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  flowId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isTest?: Maybe<Scalars['Int']>;
  orderName?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type SessionEdge = {
  __typename?: 'SessionEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Session */
  node: Session;
};

export type SessionFilter = {
  and?: Maybe<Array<SessionFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  flowId?: Maybe<SessionFlowIdFilterComparison>;
  id?: Maybe<StringFieldComparison>;
  isTest?: Maybe<BooleanFieldComparison>;
  or?: Maybe<Array<SessionFilter>>;
  orderName?: Maybe<StringFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type SessionFlowIdFilterComparison = {
  eq?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
};

export type SessionMaxAggregate = {
  __typename?: 'SessionMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  orderName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SessionMinAggregate = {
  __typename?: 'SessionMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  flowId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  orderName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SessionResult = {
  __typename?: 'SessionResult';
  matchedOptionIds: Array<Scalars['String']>;
  product: ShopifyProduct;
  productId: Scalars['String'];
};

export type SessionSelection = {
  __typename?: 'SessionSelection';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  node: FlowNode;
  optionId?: Maybe<Scalars['UUID']>;
  updatedAt: Scalars['DateTime'];
};

export type SessionSort = {
  direction: SortDirection;
  field: SessionSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum SessionSortFields {
  CreatedAt = 'createdAt',
  FlowId = 'flowId',
  Id = 'id',
  IsTest = 'isTest',
  OrderName = 'orderName',
  UpdatedAt = 'updatedAt',
}

export enum SessionStatus {
  Completed = 'COMPLETED',
  Converted = 'CONVERTED',
  Open = 'OPEN',
  Started = 'STARTED',
}

export type ShopifyCollection = {
  __typename?: 'ShopifyCollection';
  handle: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type ShopifyCollectionByHandleInput = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  handle: Scalars['String'];
  last?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
  reverse?: Maybe<Scalars['Boolean']>;
  sortKey?: Maybe<ProductCollectionSortKeys>;
};

export type ShopifyCollectionConnection = {
  __typename?: 'ShopifyCollectionConnection';
  edges: Array<ShopifyCollectionEdge>;
  pageInfo: ShopifyPageInfo;
};

export type ShopifyCollectionEdge = {
  __typename?: 'ShopifyCollectionEdge';
  cursor: Scalars['String'];
  node: ShopifyCollection;
};

export type ShopifyCollectionInput = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
  reverse?: Maybe<Scalars['Boolean']>;
  sortKey?: Maybe<CollectionSortKeys>;
};

export type ShopifyCreateSubscriptionInput = {
  tierId?: Maybe<Scalars['UUID']>;
};

export type ShopifyFeaturedImage = {
  __typename?: 'ShopifyFeaturedImage';
  transformedSrc: Scalars['String'];
};

export type ShopifyMetafield = {
  __typename?: 'ShopifyMetafield';
  value: Scalars['String'];
};

export type ShopifyMoneyV2 = {
  __typename?: 'ShopifyMoneyV2';
  /** Decimal money amount. */
  amount: Scalars['String'];
  /** Currency of the money. */
  currencyCode: CurrencyCode;
};

export type ShopifyPageInfo = {
  __typename?: 'ShopifyPageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
};

export type ShopifyProduct = {
  __typename?: 'ShopifyProduct';
  featuredImage?: Maybe<ShopifyFeaturedImage>;
  handle: Scalars['String'];
  id: Scalars['ID'];
  onlineStoreUrl?: Maybe<Scalars['String']>;
  priceRangeV2: ShopifyProductPriceRangeV2;
  rating?: Maybe<ShopifyMetafield>;
  ratingCount?: Maybe<ShopifyMetafield>;
  title: Scalars['String'];
  variants: ShopifyVariantConnection;
};

export type ShopifyProductConnection = {
  __typename?: 'ShopifyProductConnection';
  edges: Array<ShopifyProductEdge>;
  pageInfo: ShopifyPageInfo;
};

export type ShopifyProductEdge = {
  __typename?: 'ShopifyProductEdge';
  cursor: Scalars['String'];
  node: ShopifyProduct;
};

export type ShopifyProductPriceRangeV2 = {
  __typename?: 'ShopifyProductPriceRangeV2';
  maxVariantPrice: ShopifyMoneyV2;
  minVariantPrice: ShopifyMoneyV2;
};

export type ShopifyProductTypesInput = {
  first: Scalars['Float'];
};

export type ShopifyProductsByIdsInput = {
  ids: Array<Scalars['ID']>;
};

export type ShopifyProductsInput = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Float']>;
  query?: Maybe<Scalars['String']>;
  reverse?: Maybe<Scalars['Boolean']>;
  sortKey?: Maybe<ProductSortKeys>;
};

export type ShopifyStringConnection = {
  __typename?: 'ShopifyStringConnection';
  edges: Array<ShopifyStringEdge>;
  pageInfo: ShopifyPageInfo;
};

export type ShopifyStringEdge = {
  __typename?: 'ShopifyStringEdge';
  cursor: Scalars['String'];
  node: Scalars['String'];
};

export type ShopifyVariant = {
  __typename?: 'ShopifyVariant';
  id: Scalars['ID'];
};

export type ShopifyVariantConnection = {
  __typename?: 'ShopifyVariantConnection';
  /** Only returns the first 2 variants. */
  edges: Array<ShopifyVariantEdge>;
};

export type ShopifyVariantEdge = {
  __typename?: 'ShopifyVariantEdge';
  node: ShopifyVariant;
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST',
}

export type StartSessionInput = {
  flowId: Scalars['UUID'];
  referrer?: Maybe<Scalars['String']>;
  screenWidth?: Maybe<Scalars['Float']>;
};

/** File storage type. */
export enum StorageType {
  Google = 'Google',
  Local = 'Local',
}

export type Store = {
  __typename?: 'Store';
  /** The two-letter code for the country of the store. */
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currencyCode?: Maybe<Scalars['String']>;
  /** The e-mail address of the store owner. */
  email?: Maybe<Scalars['String']>;
  flowContainers: StoreFlowContainersConnection;
  id: Scalars['String'];
  /** Whether the store is created for demo or review purposes. */
  isTest: Scalars['Boolean'];
  /** The name of the store. */
  name?: Maybe<Scalars['String']>;
  /** Connected Shopify store ID. */
  shopifyId: Scalars['String'];
  shopifyPlan?: Maybe<Scalars['String']>;
  subscriptionStatus: SubscriptionStatus;
  tier: Tier;
  timezone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type StoreFlowContainersArgs = {
  filter?: Maybe<FlowContainerFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<FlowContainerSort>>;
};

export type StoreAggregateGroupBy = {
  __typename?: 'StoreAggregateGroupBy';
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isTest?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  shopifyId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StoreConnection = {
  __typename?: 'StoreConnection';
  /** Array of edges. */
  edges: Array<StoreEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type StoreCountAggregate = {
  __typename?: 'StoreCountAggregate';
  country?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isTest?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  shopifyId?: Maybe<Scalars['Int']>;
  subscriptionStatus?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type StoreCountryFilterComparison = {
  eq?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
};

export type StoreDeleteResponse = {
  __typename?: 'StoreDeleteResponse';
  /** The two-letter code for the country of the store. */
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currencyCode?: Maybe<Scalars['String']>;
  /** The e-mail address of the store owner. */
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  /** Whether the store is created for demo or review purposes. */
  isTest?: Maybe<Scalars['Boolean']>;
  /** The name of the store. */
  name?: Maybe<Scalars['String']>;
  /** Connected Shopify store ID. */
  shopifyId?: Maybe<Scalars['String']>;
  shopifyPlan?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  /** The tier the store is on. */
  tier?: Maybe<Tier>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StoreEdge = {
  __typename?: 'StoreEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Store */
  node: Store;
};

export type StoreEngagementFilterInput = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type StoreEngagementOutput = {
  __typename?: 'StoreEngagementOutput';
  engagements: Scalars['Float'];
  store: Store;
};

export type StoreFilter = {
  and?: Maybe<Array<StoreFilter>>;
  country?: Maybe<StoreCountryFilterComparison>;
  createdAt?: Maybe<DateFieldComparison>;
  email?: Maybe<StringFieldComparison>;
  flowContainers?: Maybe<StoreFilterFlowContainerFilter>;
  id?: Maybe<StringFieldComparison>;
  isTest?: Maybe<StoreIsTestFilterComparison>;
  name?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<StoreFilter>>;
  shopifyId?: Maybe<StringFieldComparison>;
  subscriptionStatus?: Maybe<SubscriptionStatusFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type StoreFilterFlowContainerFilter = {
  and?: Maybe<Array<StoreFilterFlowContainerFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<StoreFilterFlowContainerFilter>>;
  status?: Maybe<FlowContainerStatusFilterComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type StoreFlowContainersConnection = {
  __typename?: 'StoreFlowContainersConnection';
  /** Array of edges. */
  edges: Array<FlowContainerEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int'];
};

export type StoreIsTestFilterComparison = {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
};

export type StoreMaxAggregate = {
  __typename?: 'StoreMaxAggregate';
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shopifyId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StoreMinAggregate = {
  __typename?: 'StoreMinAggregate';
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shopifyId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StoreSort = {
  direction: SortDirection;
  field: StoreSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum StoreSortFields {
  Country = 'country',
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  IsTest = 'isTest',
  Name = 'name',
  ShopifyId = 'shopifyId',
  SubscriptionStatus = 'subscriptionStatus',
  UpdatedAt = 'updatedAt',
}

export type StringFieldComparison = {
  eq?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  iLike?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  like?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  notILike?: Maybe<Scalars['String']>;
  notIn?: Maybe<Array<Scalars['String']>>;
  notLike?: Maybe<Scalars['String']>;
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
}

export type SubscriptionStatusFilterComparison = {
  eq?: Maybe<SubscriptionStatus>;
  gt?: Maybe<SubscriptionStatus>;
  gte?: Maybe<SubscriptionStatus>;
  iLike?: Maybe<SubscriptionStatus>;
  in?: Maybe<Array<SubscriptionStatus>>;
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
  like?: Maybe<SubscriptionStatus>;
  lt?: Maybe<SubscriptionStatus>;
  lte?: Maybe<SubscriptionStatus>;
  neq?: Maybe<SubscriptionStatus>;
  notILike?: Maybe<SubscriptionStatus>;
  notIn?: Maybe<Array<SubscriptionStatus>>;
  notLike?: Maybe<SubscriptionStatus>;
};

export type Tier = {
  __typename?: 'Tier';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  maxEngagements: Scalars['Float'];
  name: Scalars['String'];
  /** Price in cents. */
  price: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type TierAggregateGroupBy = {
  __typename?: 'TierAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TierAvgAggregate = {
  __typename?: 'TierAvgAggregate';
  price?: Maybe<Scalars['Float']>;
};

export type TierConnection = {
  __typename?: 'TierConnection';
  /** Array of edges. */
  edges: Array<TierEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type TierCountAggregate = {
  __typename?: 'TierCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isPublic?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type TierEdge = {
  __typename?: 'TierEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Tier */
  node: Tier;
};

export type TierFilter = {
  and?: Maybe<Array<TierFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  isPublic?: Maybe<BooleanFieldComparison>;
  name?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<TierFilter>>;
  price?: Maybe<NumberFieldComparison>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type TierInput = {
  isDefault: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  maxEngagements: Scalars['Float'];
  name: Scalars['String'];
  /** Price in cents. */
  price: Scalars['Float'];
};

export type TierMaxAggregate = {
  __typename?: 'TierMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TierMinAggregate = {
  __typename?: 'TierMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TierSort = {
  direction: SortDirection;
  field: TierSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum TierSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsPublic = 'isPublic',
  Name = 'name',
  Price = 'price',
  UpdatedAt = 'updatedAt',
}

export type TierSumAggregate = {
  __typename?: 'TierSumAggregate';
  price?: Maybe<Scalars['Float']>;
};

export type Translation = {
  __typename?: 'Translation';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isRtl: Scalars['Boolean'];
  locale: Scalars['String'];
  messages?: Maybe<Array<KeyStringValuePair>>;
  updatedAt: Scalars['DateTime'];
};

export type TranslationAggregateGroupBy = {
  __typename?: 'TranslationAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TranslationConnection = {
  __typename?: 'TranslationConnection';
  /** Array of edges. */
  edges: Array<TranslationEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type TranslationCountAggregate = {
  __typename?: 'TranslationCountAggregate';
  createdAt?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

export type TranslationDeleteResponse = {
  __typename?: 'TranslationDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRtl?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  messages?: Maybe<Array<KeyStringValuePair>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TranslationEdge = {
  __typename?: 'TranslationEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor'];
  /** The node containing the Translation */
  node: Translation;
};

export type TranslationFilter = {
  and?: Maybe<Array<TranslationFilter>>;
  createdAt?: Maybe<DateFieldComparison>;
  id?: Maybe<StringFieldComparison>;
  or?: Maybe<Array<TranslationFilter>>;
  updatedAt?: Maybe<DateFieldComparison>;
};

export type TranslationMaxAggregate = {
  __typename?: 'TranslationMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TranslationMinAggregate = {
  __typename?: 'TranslationMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TranslationSort = {
  direction: SortDirection;
  field: TranslationSortFields;
  nulls?: Maybe<SortNulls>;
};

export enum TranslationSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
}

export type UrlFile = {
  __typename?: 'URLFile';
  /** BlurHash */
  blurhash?: Maybe<Scalars['String']>;
  /** Height */
  height?: Maybe<Scalars['Float']>;
  /** URL */
  url: Scalars['URL'];
  /** Width */
  width?: Maybe<Scalars['Float']>;
};

export type UrlFileInput = {
  /** BlurHash */
  blurhash?: Maybe<Scalars['String']>;
  /** Height */
  height?: Maybe<Scalars['Float']>;
  /** URL */
  url: Scalars['URL'];
  /** Width */
  width?: Maybe<Scalars['Float']>;
};

export type UpdateFlowContainerInput = {
  integrationMetadata?: Maybe<FlowIntegrationMetadataInput>;
  name?: Maybe<Scalars['String']>;
  seoDescription?: Maybe<Scalars['String']>;
  seoTitle?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<FlowContainerStatus>;
};

export type UpdateFlowInput = {
  colorScheme?: Maybe<FlowColorScheme>;
  enableAddToCart?: Maybe<Scalars['Boolean']>;
  enableMoreResults?: Maybe<Scalars['Boolean']>;
  enableRestart?: Maybe<Scalars['Boolean']>;
  enableReviews?: Maybe<Scalars['Boolean']>;
  fontFamily?: Maybe<Scalars['String']>;
  hasProgressBar?: Maybe<Scalars['Boolean']>;
  hasStepIndicator?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<FlowLocale>;
  primaryColor?: Maybe<Scalars['String']>;
  productIds?: Maybe<Array<Scalars['String']>>;
  resultsAmount?: Maybe<Scalars['Float']>;
  resultsDescription?: Maybe<Scalars['String']>;
  resultsTitle?: Maybe<Scalars['String']>;
  translationId?: Maybe<Scalars['String']>;
};

export type UpdateFlowLogicInput = {
  flowId: Scalars['UUID'];
  logic: Array<FlowNodeOptionLogicInput>;
};

export type UpdateFlowNodeInput = {
  description?: Maybe<Scalars['String']>;
  flowId?: Maybe<Scalars['String']>;
  image?: Maybe<UrlFileInput>;
  isRequired?: Maybe<Scalars['Boolean']>;
  layout?: Maybe<FlowNodeLayout>;
  options?: Maybe<Array<FlowNodeOptionInput>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<FlowNodeType>;
  video?: Maybe<UrlFileInput>;
};

export type UpdateFlowNodeOptionImageInput = {
  image: UrlFileInput;
  nodeId: Scalars['UUID'];
  optionId: Scalars['UUID'];
};

export type UpdateIntegrationInput = {
  privateKey?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
};

export type UpdateManyFlowContainersInput = {
  /** Filter used to find fields to update */
  filter: FlowContainerUpdateFilter;
  /** The update to apply to all records found using the filter */
  update: UpdateFlowContainerInput;
};

export type UpdateManyResponse = {
  __typename?: 'UpdateManyResponse';
  /** The number of records updated. */
  updatedCount: Scalars['Int'];
};

export type UpdateOneFlowContainerInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateFlowContainerInput;
};

export type UpdateOneFlowInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateFlowInput;
};

export type UpdateOneFlowNodeInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateFlowNodeInput;
};

export type UpdateOneIntegrationInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateIntegrationInput;
};

export type UpdateOneStoreInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateStoreInput;
};

export type UpdateOneTierInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateTier;
};

export type UpdateOneTranslationInput = {
  /** The id of the record to update */
  id: Scalars['ID'];
  /** The update to apply. */
  update: UpdateTranslation;
};

export type UpdateStoreInput = {
  tierId: Scalars['UUID'];
};

export type UpdateTier = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  maxEngagements?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  /** Price in cents. */
  price?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateTranslation = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  isRtl?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  messages?: Maybe<Array<KeyStringValuePairInput>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UploadFlowContainerOgImageInput = {
  id: Scalars['UUID'];
  ogImage: Scalars['Upload'];
};

export type UploadFlowNodeOptionImageInput = {
  image: Scalars['Upload'];
  nodeId: Scalars['UUID'];
  optionId: Scalars['UUID'];
};

export type AdminSessionFragmentFragment = {
  __typename?: 'AdminSession';
} & Pick<AdminSession, 'email' | 'firstName' | 'lastName'>;

export type TierFragmentFragment = { __typename?: 'Tier' } & Pick<
  Tier,
  | 'id'
  | 'isDefault'
  | 'isPublic'
  | 'maxEngagements'
  | 'name'
  | 'price'
  | 'updatedAt'
  | 'createdAt'
>;

export type AuthenticateAdminMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type AuthenticateAdminMutation = { __typename?: 'Mutation' } & {
  authenticateAdmin: {
    __typename?: 'AdminSession';
  } & AdminSessionFragmentFragment;
};

export type CreateTierMutationVariables = Exact<{
  input: CreateOneTierInput;
}>;

export type CreateTierMutation = { __typename?: 'Mutation' } & {
  createOneTier: { __typename?: 'Tier' } & TierFragmentFragment;
};

export type LogoutAdminMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutAdminMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'logoutAdmin'
>;

export type SetDefaultTierMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type SetDefaultTierMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'setDefaultTier'
>;

export type UpdateTierMutationVariables = Exact<{
  input: UpdateOneTierInput;
}>;

export type UpdateTierMutation = { __typename?: 'Mutation' } & {
  updateOneTier: { __typename?: 'Tier' } & TierFragmentFragment;
};

export type AdminSessionQueryVariables = Exact<{ [key: string]: never }>;

export type AdminSessionQuery = { __typename?: 'Query' } & {
  adminSession?: Maybe<
    { __typename?: 'AdminSession' } & AdminSessionFragmentFragment
  >;
};

export type EngagementQueryVariables = Exact<{
  input: StoreEngagementFilterInput;
}>;

export type EngagementQuery = { __typename?: 'Query' } & {
  storeEngagements: Array<
    { __typename?: 'StoreEngagementOutput' } & Pick<
      StoreEngagementOutput,
      'engagements'
    > & {
        store: { __typename?: 'Store' } & Pick<Store, 'name' | 'shopifyId'> & {
            tier: { __typename?: 'Tier' } & Pick<Tier, 'maxEngagements'>;
          };
      }
  >;
};

export type FlowBuilderTokenQueryVariables = Exact<{ [key: string]: never }>;

export type FlowBuilderTokenQuery = { __typename?: 'Query' } & Pick<
  Query,
  'accessToken'
>;

export type StoreQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StoreQuery = { __typename?: 'Query' } & {
  store?: Maybe<
    { __typename?: 'Store' } & Pick<
      Store,
      | 'country'
      | 'createdAt'
      | 'email'
      | 'id'
      | 'name'
      | 'shopifyId'
      | 'shopifyPlan'
      | 'subscriptionStatus'
      | 'timezone'
    > & {
        tier: { __typename?: 'Tier' } & Pick<Tier, 'name'>;
        flowContainers: { __typename?: 'StoreFlowContainersConnection' } & Pick<
          StoreFlowContainersConnection,
          'totalCount'
        > & {
            edges: Array<
              { __typename?: 'FlowContainerEdge' } & {
                node: { __typename?: 'FlowContainer' } & Pick<
                  FlowContainer,
                  'createdAt' | 'id' | 'name' | 'slug' | 'status'
                > & {
                    flows: Array<
                      { __typename?: 'Flow' } & Pick<
                        Flow,
                        'amountOfSteps' | 'id' | 'status'
                      > & {
                          nodes: Array<
                            { __typename?: 'FlowNode' } & Pick<
                              FlowNode,
                              | 'createdAt'
                              | 'id'
                              | 'flowId'
                              | 'type'
                              | 'title'
                              | 'order'
                              | 'isRequired'
                            > & {
                                options?: Maybe<
                                  Array<
                                    { __typename?: 'FlowNodeOption' } & Pick<
                                      FlowNodeOption,
                                      'label'
                                    >
                                  >
                                >;
                              }
                          >;
                        }
                    >;
                  };
              }
            >;
          };
      }
  >;
};

export type StoresQueryVariables = Exact<{
  filter?: Maybe<StoreFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<StoreSort> | StoreSort>;
}>;

export type StoresQuery = { __typename?: 'Query' } & {
  stores: { __typename?: 'StoreConnection' } & {
    edges: Array<
      { __typename?: 'StoreEdge' } & Pick<StoreEdge, 'cursor'> & {
          node: { __typename?: 'Store' } & Pick<
            Store,
            | 'id'
            | 'createdAt'
            | 'name'
            | 'email'
            | 'shopifyId'
            | 'isTest'
            | 'country'
            | 'subscriptionStatus'
          > & {
              tier: { __typename?: 'Tier' } & Pick<Tier, 'name'>;
              flowContainers: {
                __typename?: 'StoreFlowContainersConnection';
              } & Pick<StoreFlowContainersConnection, 'totalCount'>;
            };
        }
    >;
    pageInfo: { __typename?: 'PageInfo' } & Pick<
      PageInfo,
      'hasNextPage' | 'hasPreviousPage' | 'endCursor' | 'startCursor'
    >;
  };
};

export type TierQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TierQuery = { __typename?: 'Query' } & {
  tier?: Maybe<{ __typename?: 'Tier' } & TierFragmentFragment>;
};

export type TiersQueryVariables = Exact<{
  filter?: Maybe<TierFilter>;
  paging?: Maybe<CursorPaging>;
  sorting?: Maybe<Array<TierSort> | TierSort>;
}>;

export type TiersQuery = { __typename?: 'Query' } & {
  tiers: { __typename?: 'TierConnection' } & {
    edges: Array<
      { __typename?: 'TierEdge' } & Pick<TierEdge, 'cursor'> & {
          node: { __typename?: 'Tier' } & TierFragmentFragment;
        }
    >;
    pageInfo: { __typename?: 'PageInfo' } & Pick<
      PageInfo,
      'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'
    >;
  };
};

export const AdminSessionFragmentFragmentDoc = gql`
  fragment AdminSessionFragment on AdminSession {
    email
    firstName
    lastName
  }
`;
export const TierFragmentFragmentDoc = gql`
  fragment TierFragment on Tier {
    id
    isDefault
    isPublic
    maxEngagements
    name
    price
    updatedAt
    createdAt
  }
`;
export const AuthenticateAdminDocument = gql`
  mutation authenticateAdmin($token: String!) {
    authenticateAdmin(token: $token) {
      ...AdminSessionFragment
    }
  }
  ${AdminSessionFragmentFragmentDoc}
`;
export const CreateTierDocument = gql`
  mutation createTier($input: CreateOneTierInput!) {
    createOneTier(input: $input) {
      ...TierFragment
    }
  }
  ${TierFragmentFragmentDoc}
`;
export const LogoutAdminDocument = gql`
  mutation logoutAdmin {
    logoutAdmin
  }
`;
export const SetDefaultTierDocument = gql`
  mutation setDefaultTier($id: String!) {
    setDefaultTier(id: $id)
  }
`;
export const UpdateTierDocument = gql`
  mutation updateTier($input: UpdateOneTierInput!) {
    updateOneTier(input: $input) {
      ...TierFragment
    }
  }
  ${TierFragmentFragmentDoc}
`;
export const AdminSessionDocument = gql`
  query adminSession {
    adminSession {
      ...AdminSessionFragment
    }
  }
  ${AdminSessionFragmentFragmentDoc}
`;
export const EngagementDocument = gql`
  query engagement($input: StoreEngagementFilterInput!) {
    storeEngagements(input: $input) {
      engagements
      store {
        name
        shopifyId
        tier {
          maxEngagements
        }
      }
    }
  }
`;
export const FlowBuilderTokenDocument = gql`
  query flowBuilderToken {
    accessToken
  }
`;
export const StoreDocument = gql`
  query store($id: ID!) {
    store(id: $id) {
      country
      createdAt
      email
      id
      name
      shopifyId
      shopifyPlan
      subscriptionStatus
      timezone
      tier {
        name
      }
      flowContainers {
        edges {
          node {
            createdAt
            id
            name
            slug
            status
            flows {
              amountOfSteps
              id
              status
              nodes {
                createdAt
                id
                flowId
                type
                title
                order
                isRequired
                options {
                  label
                }
              }
            }
          }
        }
        totalCount
      }
    }
  }
`;
export const StoresDocument = gql`
  query stores(
    $filter: StoreFilter
    $paging: CursorPaging
    $sorting: [StoreSort!]
  ) {
    stores(filter: $filter, paging: $paging, sorting: $sorting) {
      edges {
        cursor
        node {
          id
          createdAt
          name
          email
          shopifyId
          isTest
          country
          subscriptionStatus
          tier {
            name
          }
          flowContainers {
            totalCount
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`;
export const TierDocument = gql`
  query tier($id: ID!) {
    tier(id: $id) {
      ...TierFragment
    }
  }
  ${TierFragmentFragmentDoc}
`;
export const TiersDocument = gql`
  query tiers(
    $filter: TierFilter
    $paging: CursorPaging
    $sorting: [TierSort!]
  ) {
    tiers(paging: $paging, filter: $filter, sorting: $sorting) {
      edges {
        cursor
        node {
          ...TierFragment
        }
      }
      pageInfo {
        startCursor
        hasPreviousPage
        hasNextPage
        endCursor
      }
    }
  }
  ${TierFragmentFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  return {
    authenticateAdmin(
      variables: AuthenticateAdminMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<AuthenticateAdminMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AuthenticateAdminMutation>(
            AuthenticateAdminDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'authenticateAdmin',
      );
    },
    createTier(
      variables: CreateTierMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<CreateTierMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateTierMutation>(CreateTierDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'createTier',
      );
    },
    logoutAdmin(
      variables?: LogoutAdminMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<LogoutAdminMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<LogoutAdminMutation>(LogoutAdminDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'logoutAdmin',
      );
    },
    setDefaultTier(
      variables: SetDefaultTierMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<SetDefaultTierMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SetDefaultTierMutation>(
            SetDefaultTierDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'setDefaultTier',
      );
    },
    updateTier(
      variables: UpdateTierMutationVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<UpdateTierMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateTierMutation>(UpdateTierDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'updateTier',
      );
    },
    adminSession(
      variables?: AdminSessionQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<AdminSessionQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AdminSessionQuery>(AdminSessionDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'adminSession',
      );
    },
    engagement(
      variables: EngagementQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<EngagementQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<EngagementQuery>(EngagementDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'engagement',
      );
    },
    flowBuilderToken(
      variables?: FlowBuilderTokenQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<FlowBuilderTokenQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<FlowBuilderTokenQuery>(
            FlowBuilderTokenDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        'flowBuilderToken',
      );
    },
    store(
      variables: StoreQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<StoreQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<StoreQuery>(StoreDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'store',
      );
    },
    stores(
      variables?: StoresQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<StoresQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<StoresQuery>(StoresDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'stores',
      );
    },
    tier(
      variables: TierQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<TierQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TierQuery>(TierDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'tier',
      );
    },
    tiers(
      variables?: TiersQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<TiersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TiersQuery>(TiersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'tiers',
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
