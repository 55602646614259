import { useEffect } from 'react';
import NProgress from 'nprogress';

export const LazyLoad = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  });

  return null;
};
