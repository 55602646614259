import { useQueryClient, useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { Tier, TierQuery } from '~/graphql/sdk';

import { useSdk } from './useSdk';

export const useTier = (id: string) => {
  const sdk = useSdk();
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery(['tier', id], () =>
    sdk.tier({ id }).then((res) => res.tier),
  );

  const updateTier = useMutation(
    async (data: any) =>
      sdk
        .updateTier({
          input: {
            id,
            update: {
              isPublic: data.isPublic,
              isDefault: data.isDefault,
              name: data.name,
              price: data.price,
              maxEngagements: data.maxEngagements,
            },
          },
        })
        .then((res) => res.updateOneTier),
    {
      onMutate: async (updatedTier) => {
        await queryClient.cancelQueries(['tier', id]);

        // grab current tier snapshot
        const tierSnapshot = queryClient.getQueryData(['tier', id]);

        queryClient.setQueryData(['tier', id], updatedTier);

        // and store that in ctx
        return { tierSnapshot };
      },
      onError: (error, _, ctx) => {
        toast('Error updating tier', {
          type: 'error',
          position: 'bottom-right',
        });

        if (process.env.NODE_ENV !== 'production') {
          console.error(error);
        }

        queryClient.setQueryData(
          ['tier', id],
          (ctx as { tierSnapshot: TierQuery['tier'] }).tierSnapshot,
        );
      },
      onSuccess: () => {
        toast('Successfully updated tier', {
          type: 'success',
          position: 'bottom-right',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['tier', id]);
        updateTier.reset();
      },
    },
  );

  const setDefaultTier = useMutation(
    (id: Tier['id']) => sdk.setDefaultTier({ id }),
    {
      onMutate: async (id) => {
        await queryClient.cancelQueries(['tier', id]);

        const tiersSnapshot = queryClient.getQueryData(['tier', id]);

        queryClient.setQueryData(['tier', id], (oldTier) => ({
          ...(oldTier as TierQuery['tier']),
          isDefault: true,
        }));

        return { tiersSnapshot };
      },
      onError: (error, _, ctx) => {
        toast('Error setting default tier', {
          type: 'error',
          position: 'bottom-right',
        });

        if (process.env.NODE_ENV !== 'production') {
          console.error(error);
        }

        queryClient.setQueryData(
          ['tier', id],
          (ctx as { tiersSnapshot: TierQuery['tier'] }).tiersSnapshot,
        );
      },
      onSuccess: () => {
        toast('Successfully set tier as default', {
          type: 'success',
          position: 'bottom-right',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['tier', id]);
        setDefaultTier.reset();
      },
    },
  );

  return { tier: data, isLoading, error, updateTier, setDefaultTier };
};
